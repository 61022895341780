
import React, { Component } from 'react';
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

class ENUpcycling extends Component {

    render() {
        return (
            <Container>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Ykopank - upcycling - Legal</title>
                <meta name="description" content="What is upcycling" />
            </Helmet>
<h1>What Is Upcycling?</h1>

<h2>What’s the general idea</h2>
<p>Upcycling consists in giving new life to objects and materials we have no use for, especially those designed to be thrown away. It’s really about remodelling, or simply put, crafting new life into these materials by giving them a new use or function.
</p>
<h2>Upcycling’s beginnings</h2>
<p>Former engineer turned Interior decorator Reiner Pilz came up with the term ‘surcyclage’ or ‘upcycling’ as we know it in the mid 90’s. He differentiates it from the conventional recycling term ‘downcycling’ which, he explains, not only diminishes the value of said recycled object, but furthermore “destroys all”. Conversely, upcycling is said to be “the best form of recycling” meaning that unused objects rather gain value than lose some. In 2002, his idea was picked up by William McDonough & Michael Braungart. The former is an architect as well as a designer of world renown who focuses on redefining the idea of waste in the world of design and of architecture. The latter is a chemist who supports the belief that we can take inspiration from life supporting organisations to make our carbon print positive. Both explain these notions in their 2002 book: “<u>Cradle To Cradle: Remaking The Way We Make Things</u>”. Throughout the book, they lay down the basics of upcycling in the industrial world. A big chunk relates to the “eco conception”, where they also dig into environmental ethics. They describe the mantra of an industrial chain of production where the whole process, from conception, production to reuse of product entails: no pollution and 100% recuperation of the materials.
</p>
<h2>Upcycling, an ecologic and economic venture</h2>
<p>Contrary to traditional recycling, upcycling doesn’t alter the integrity of a material. It’s about preserving it’s qualities throughout it’s lifecycle. Recycling means chemically transforming/destroying matter. With upcycling, we don’t need to go through “composting”. It’s about reusing, not transforming matter in order to create a new product. In all, it’s an economy on the raw materials necessary for the process of traditional recycling, thereby relieving the need for fossil fuels.
</p>
<h2>Upcycling & Circular Economy</h2>
<p>Upcycling inserts itself in the world of circular economy and reuse. Tagged as smart recycling, it certainly copes with a broader view of a sustainable environment.
</p>
<h2>Creation, Craftsmanship & Upcycling</h2>
<p>Upcycling already blends in to many parts of our society. As well as in everyday objects, such as fashion accessories, art & deco, but also in companies as well as in art galleries. Most often, upcycling is bred from artisanship. It’s even sought out to be it’s own class of craft. In order to bring new life, one must go through a great deal of creative work to reuse objects, be it creative thinking or simply in design. At last, the creative freedom that comes from upcycling permits to create or recreate genuine and original things, such as one might find at the Ykopank upcycling workshop.
</p>
<p><i>We’d like to thank you for visiting this exhibition and the upcycling works of our workshop. We hope your visit brought you pleasure and happy surprises.</i></p>

            </Container>
        );
    }


}

export default ENUpcycling ;