
import React, { Component } from 'react';
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

class FRUpcycling extends Component {

    render() {
        return (
            <Container>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Ykopank - upcycling - Legal</title>
                <meta name="description" content="Qu'est ce que l'upcycling - le surcyclage" />
            </Helmet>
<h1>Qu'est-ce que l’upcycling ou le surcyclage ?</h1>
<h2>Principe générale de l'upcycling</h2>
<p>L’upcycling est une activité qui consiste à récupérer des objets et des matériaux dont nous n'avons plus besoin, 
    le plus souvent destinés à être jetés.<br />
Il s'agit de les modifier, de les refaçonner, afin de leur donner une nouvelle fonction et offrir au produit initial un nouvel usage.</p>

<h2>Naissance de l'upcycling</h2>
 

<p>C'est au milieu des années 1990 que Reiner Pilz, ancien ingénieur devenu architecte d’intérieur, propose le terme « surcyclage » 
    sous sa forme anglaise « Upcycling ». 
    Par ce terme, Reiner Pilz oppose le recyclage traditionnel qu'il nomme « downcycling » désignant  
    non seulement un recyclage qui diminue la valeur de l'objet recyclé, mais qui de surcroît « détruit tout ». 
    <br/>A l'inverse, l’upcycling est présenté comme "un recyclage par le haut" afin 
    que « les produits inutilisés gagnent de la valeur plutôt que d’en perdre ».
</p>
<p>En 2002, William McDonough et Michael Braungart reprendront les travaux de Reiner Pilz.
    Le premier est architecte et designer de renommée internationale, et travail à révolutionner la notion de déchet 
    dans les métiers de l'architecture et du design.
    Michael Braungart est quant à lui chimiste et soutient que nous pouvons avoir une empreinte 
    écologique positive en s'inspirant des systèmes 
    qui supportent la vie.
    <br/>Tous deux détaillent ces notions dans leur ouvrage de 2002  : <i><b>Cradle to Cradle : « Remaking the way we make things »</b></i>
     (Du berceau au berceau : créer et recycler à l’infini).</p>

<p>
     Dans ce livre ils posent les fondements de l'upcycling dans le milieu industriel.
     Une partie est consacrée à l'éco conception. Ils y abordent également les concepts d'<b>éthique environnementale </b>
     et décrivent une philosophie de la production industrielle intégrant à tous les niveaux du processus (conception,
     production, réutilisation du produit) une exigence écologique dont le principe est :   
     <b> zéro pollution et 100 % réutilisé</b>.
</p>
<h2>Upcycling, une démarche écologique et économique.</h2 ><br />
<p>A la différence du recyclage traditionnel, l'upcycling n'altére pas l’intégrité de la matière. 
    Il s'agit au contraire de préserver sa qualité tout au long de son emploi.
En effet le recyclage nécéssite des étapes de destruction et/ou de transformation chimique de la matière.
Or ce sont précisément ces étapes dites "de compostage" qui sont évitées dans l'upcycling.
<br/>Avec l'upcycling, il s'agit donc de réutiliser, pas de créer un nouveau produit nécéssitant au préalable une transformation de matière. 
<br/>In fine, il s'agit d'économiser de l’énergie grise ainsi que les coûts liés aux matières premières systématiquement nécessaires  
 au processus de transformation et de fabrication dans le recyclage traditionnel.</p>
<br/>

<h2>Upcycling et économie circulaire</h2>
<p>L'upcycling s'inscrit alors dans le mouvement de l'économie circulaire et du réemploi.<br />
Considéré comme un recyclage par le haut cette action s'intègre totalement dans <b>une volonté plus large encore de développement durable</b>.<br />
</p>
<h2>Création, artisanat et upcycling</h2>
<p>Le surcyclage se rencontre aujourd'hui dans diverse domaines. A la fois dans les objets du quotidien, 
    les accessoires de mode, les objets de déco, on le retouve également en entreprise ainsi que dans les galeries d’art.<br/>
Le plus souvent l'upcycling relève d’une production artisanale. Il est d’ailleurs considéré comme un réel <b>artisanat 
constituant une activité à part entière</b>.</p>
<p>Car pour leur donner une nouvelle vie, la réutilisation des objets demande un important travail de création. Un travail à la fois de design 
    et de réflexion technique.<br/>
Enfin, laissant libre cours à l’imagination et à la créativité, le concept d'upcycling permet de créer et recréer des pièces 
quasi-uniques et originales.
C'est ce que propose en grande majorité l'atelier créatif Ykopank upcycling.</p>

<h4>En vous remerciant d'être venu visité le site d'exposition des oeuvres d'upcycling de notre atelier, 
    je vous en souhaite également une bonne visite faite de bonnes surprises et de plaisir.</h4>
RIEN NE SE PERD, TOUT SE TRANSFORME

            </Container>
        );
    }


}

export default FRUpcycling ;