import React, { Component } from "react";
import GridSet from "../Medias/Gallery_0/GridSet.js";
import Shuffle from "./ShuffleObj";
import ProjectCardItem from "./ProjectCardItem";

class OtherProjectSuggest extends Component {
  render() {
    return (
      <div>
        {Shuffle(GridSet)
          .filter((thumb) => thumb.title !== this.props.exept)
          .map((thumb, i) => {
            return (
              i < 8 && (
                <span
                  key={i}
                  style={{
                    display: "flex",
                    float: "left",
                    height: "100px",
                    width: "100px",
                    marginRight: "10px",
                  }}
                >
                  <ProjectCardItem detail={false} key={i} card={thumb} />
                </span>
              )
            );
          })}
      </div>
    );
  }
}

export default OtherProjectSuggest;
