import React from "react";
import { Navbar, Nav } from "react-bootstrap"; // NavDropdown
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Image from "react-bootstrap/Image";
import MainLogo from "../Medias/logo-ykopank-30.png";
import Facebook from "../Medias/icone-Facebook-haut-de-site.png";
import Instagram from "../Medias/icones-insta-haut-de-site.png";

import { RiMailAddFill } from "react-icons/ri";

const NavBar = () => {
  return (
    <Navbar
      className="sticky-top navbar-dark bg-dark"
      collapseOnSelect
      expand="lg"
      style={{
        fontSize: "0.8em",
        fontWeight: "bold",
      }}
    >

      <Navbar.Brand>
        <Link title="Retour à l'accueil" to="/">
          <Image
            thumbnail={false}
            style={{ height: "30px", width: "150px" }}
            src={MainLogo}
            rounded={true}
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        expand="lg"
        className=""
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="container-fluid ml-auto">
          <Nav.Link
            title="Sculpture upcycling"
            to="/sculptures.html"
            href="sculptures.html"
          >
            Sculptures<sup style={{ fontSize: "8px" }}>Y</sup>
          </Nav.Link>
          <Nav.Link
            title="Meubles upcycling"
            to="/meubles.html"
            href="/meubles.html"
          >
            Meubles<sup style={{ fontSize: "8px" }}>Y</sup>
          </Nav.Link>
          <Nav.Link
            title="Qu'est-ce que l'upcycling ou le sursyclage"
            to="/upcycling-c-est-quoi.html"
            href="/upcycling-c-est-quoi.html"
          >
            Upcycling ?
          </Nav.Link>

          <Nav className="ml-auto">
            <Nav.Link href="mailto:ykopank@gmail.com" title="Envoyer un Email">
              <RiMailAddFill size={32} color="white" />
            </Nav.Link>
            <Nav.Link
              href="https://www.facebook.com/ykopank/?ref=page_internal"
              title="Suivre Ykopank sur Facebook"
              target="blank"
            >
              <Image src={Facebook} />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/ykopank/"
              title="Suivre Ykopank sur Instagram"
              target="blank"
            >
              <Image src={Instagram} />
            </Nav.Link>
          </Nav>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
