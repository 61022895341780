import YkoBoite1 from "./Ykopank-Boite-Yko-1.jpg";
import YkoBoite11 from "./Ykopank-Boite-Yko-1_1.jpg";
import YkoBoite2 from "./Ykopank-Boite-Yko-2.jpg";
import YkoBoite21 from "./Ykopank-Boite-Yko-2_1.jpg";
import YkoBoite3 from "./Ykopank-Boite-Yko-3.jpg";
import YkoBoite31 from "./Ykopank-Boite-Yko-3_1.jpg";
import YkoBoite4 from "./Ykopank-Boite-Yko-4.jpg";
import YkoBoite41 from "./Ykopank-Boite-Yko-4_1.jpg";


const YkoBoite_GALERIE = [
  {
    original: YkoBoite1,
    thumbnail: YkoBoite1,
    description: "Yko Boîte. Ykopank",
    originalTitle: "Yko Boîte. Ykopank",
    originalAlt: "Upcycling -Yko Boîte. Ykopank",
  },  
  {
    original: YkoBoite2,
    thumbnail: YkoBoite2,
    description: "Yko Boîte. Ykopank",
    originalTitle: "Yko Boîte. Ykopank",
    originalAlt: "Upcycling -Yko Boîte. Ykopank",
  },
  
  {
    original: YkoBoite3,
    thumbnail: YkoBoite3,
    description: "Yko Boîte. Ykopank",
    originalTitle: "Yko Boîte. Ykopank",
    originalAlt: "Upcycling -Yko Boîte. Ykopank",
  },
  {
    original: YkoBoite4,
    thumbnail: YkoBoite4,
    description: "Yko Boîte. Ykopank",
    originalTitle: "Yko Boîte. Ykopank",
    originalAlt: "Upcycling -Yko Boîte. Ykopank",
  },
  {
    original: YkoBoite11,
    thumbnail: YkoBoite11,
    description: "Yko Boîte. Ykopank",
    originalTitle: "Yko Boîte. Ykopank",
    originalAlt: "Upcycling -Yko Boîte. Ykopank",
  },
  {
    original: YkoBoite21,
    thumbnail: YkoBoite21,
    description: "Yko Boîte. Ykopank",
    originalTitle: "Yko Boîte. Ykopank",
    originalAlt: "Upcycling -Yko Boîte. Ykopank",
  },
  {
    original: YkoBoite31,
    thumbnail: YkoBoite31,
    description: "Yko Boîte. Ykopank",
    originalTitle: "Yko Boîte. Ykopank",
    originalAlt: "Upcycling -Yko Boîte. Ykopank",
  },
  {
    original: YkoBoite41,
    thumbnail: YkoBoite41,
    description: "Yko Boîte. Ykopank",
    originalTitle: "Yko Boîte. Ykopank",
    originalAlt: "Upcycling -Yko Boîte. Ykopank",
  }
];

export default YkoBoite_GALERIE;