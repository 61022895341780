import Lalineart1 from "./Lalineart.contemporary-Ykopank-1.png";
import Lalineart2 from "./Lalineart.contemporary-Ykopank-2.jpeg";
import Lalineart3 from "./Lalineart.contemporary-Ykopank-3.jpeg";



const LALINEART_GALERIE = [
  {
    original: Lalineart1,
    thumbnail: Lalineart1,
    description: "Lalineart. Ykopank",
    originalTitle: "Lalineart. Ykopank",
    originalAlt: "Upcycling -Lalineart. Ykopank",
  },
  {
    original: Lalineart2,
    thumbnail: Lalineart2,
    description: "Lalineart. Ykopank",
    originalTitle: "Lalineart. Ykopank",
    originalAlt: "Upcycling -Lalineart. Ykopank",
  },
  {
    original: Lalineart3,
    thumbnail: Lalineart3,
    description: "Lalineart. Ykopank",
    originalTitle: "Lalineart. Ykopank",
    originalAlt: "Upcycling -Lalineart. Ykopank",
  },
];

export default LALINEART_GALERIE;