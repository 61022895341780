//Importer image et thumbail
import Mandarin1 from "./le-Madarin-Ykopank-1.png";
import Mandarin2 from "./le-Madarin-Ykopank-2.png";

//Construire la galerrie (check doc pour détail des clés)
const MANDARIN_GALERIE = [
  {
    original: Mandarin1,
    thumbnail: Mandarin1,
    description: "Mandarin. Ykopank",
    originalTitle: "Mandarin. Ykopank",
    originalAlt: "Upcycling -Mandarin. Ykopank",
  },
  {
    original: Mandarin2,
    thumbnail: Mandarin2,
    description: "Mandarin. Ykopank",
    originalTitle: "Mandarin. Ykopank",
    originalAlt: "Upcycling -Mandarin. Ykopank",
  },
];
//Exporter la galerie
export default MANDARIN_GALERIE;
