import React, { Component } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { Container, Row } from "react-bootstrap";

class Gallery extends Component {
  render() {
     //console.log(this.props);
    return (
      <Container>
        <Row>
          <ImageGallery items={this.props.galerie} showThumbnails={false} />       
        </Row>        
      </Container>
    );
  }
}

export default Gallery;
