import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import TopNavBar from "./Components/TopNavBar";
import Legal from "./Components/Legal";
import CookieConsent from "./Components/CookieConsent";
import ListeProjects from "./Components/ListeProjects";
import Footer from "./Components/Footer";
import ProjectDetails from "./Components/ProjectDetails";
import GridSet from "./Medias/Gallery_0/GridSet.js";
import ListeCategorie from "./Components/ListeCategorie";
import ScrollToTop from "./Components/ScrollToTop";
import NotFound from "./Components/404";
import { AnimatedSwitch } from 'react-router-transition';
import UpcyclingArticle from './Components/UpcyclingArticle'


const App = () => {

      return (
      
      <Router>
         
        <CookieConsent />
        <TopNavBar />
        <ScrollToTop />     
        <AnimatedSwitch 
        atEnter={{ offset: -100 }}
        atLeave={{ offset: 0 }}
        atActive={{ offset: 0 }}
        mapStyles={(styles) => ({
          transform: `translateX(${styles.offset}%)`,
        })}
      className="switch-wrapper">       
          <Route exact path="/" render={(props) => <ListeProjects />}>
          <ListeProjects />
          </Route>
          {GridSet.map((project, i) => {
            //console.log(project)
            return (
              <Route key={i} path={`${project.detailURL}`}>
                <ProjectDetails fiche={project} />
              </Route>
            );
          })}
          <Route path="/sculptures.html" component={() => <ListeCategorie />}>
            <ListeCategorie  categorie="sculpture"/>
          </Route>
          <Route path="/meubles.html" component={() => <ListeCategorie />}>
            <ListeCategorie  categorie="meuble"/>
          </Route>
          <Route path="/upcycling-c-est-quoi.html" component={() => <UpcyclingArticle />}>
            <UpcyclingArticle />
          </Route>
          
          <Route path="/Legal">
            <Legal />
          </Route>
         
        <Route component={NotFound} />
        </AnimatedSwitch>
       
        <Footer />
      </Router>
    );
  
}

export default App;
