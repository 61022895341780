import React from 'react';
import FRUpcycling from "./Fr-Upcycling";
import ENUpcycling from "./En-Upcycling";

//import OtherProjectSuggest from "./OtherProjectSuggest";

function UpcyclingArticle(props) {
    if (navigator.language.match(/fr/i)) {
      return <FRUpcycling />;
    }
    return <ENUpcycling />;
  }

export default UpcyclingArticle;
