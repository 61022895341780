//Importer image et thumbail
import LegoutDegoupille1 from "./l-egout-et-les-goupille-1-c.jpg";

//Construire la galerrie (check doc pour détail des clés)
const EGOUT_GALERIE = [
  {
    original: LegoutDegoupille1,
    thumbnail: LegoutDegoupille1,
    description: "L'égout et les goupilles. Ykopank",
    originalTitle: "L'égout et les goupilles. Ykopank",
    originalAlt: "L'égout et les goupilles. Ykopank",
  },
];
//Exporter la galerie
export default EGOUT_GALERIE;
