//Importer image et thumbail
import Paquito1 from "./Paquito.Moaï-Ykopank-1.png";
import Paquito2 from "./Paquito.Moaï-Ykopank-2.png";
import Paquito3 from "./Paquito.Moaï-Ykopank-3.png";
import Paquito4 from "./Paquito.Moaï-Ykopank-4.png";

//Construire la galerrie (check doc pour détail des clés)
const PAQUITO_GALERIE = [
  {
    original: Paquito1,
    thumbnail: Paquito1,
    description: "Paquito. Ykopank",
    originalTitle: "Paquito. Ykopank",
    originalAlt: "Upcycling -Paquito. Ykopank",
  },
  {
    original: Paquito2,
    thumbnail: Paquito2,
    description: "Paquito. Ykopank",
    originalTitle: "Paquito. Ykopank",
    originalAlt: "Upcycling -Paquito. Ykopank",
  },
  {
    original: Paquito3,
    thumbnail: Paquito3,
    description: "Paquito. Ykopank",
    originalTitle: "Paquito. Ykopank",
    originalAlt: "Upcycling -Paquito. Ykopank",
  },
  {
    original: Paquito4,
    thumbnail: Paquito4,
    description: "Paquito. Ykopank",
    originalTitle: "Paquito. Ykopank",
    originalAlt: "Upcycling -Paquito. Ykopank",
  },
];
//Exporter la galerie
export default PAQUITO_GALERIE;
