import React, { Component } from "react";
import {Col,Container } from "react-bootstrap";
import Jumbotron from 'react-bootstrap/Jumbotron';
import atelierBG from "../Medias/atelier-upcycling.jpg" ;
import { Helmet } from "react-helmet";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };
   
  }
  render() {
    return (
      <Jumbotron style={{ 
        color:"#fff",
        fontFamily: "MONTSERRAT regular",        
        backgroundImage:`url(${atelierBG}), linear-gradient(45deg,#fff 39.9%,#000 60%)`, 
        filter: "grayscale(100%)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundBlendMode: "multiply",
        backgroundRepeat: 'no-repeat'}} fluid>
          
<Helmet>
                <meta charSet="utf-8" />
                <title>Ykopank - upcycling</title>
                <meta name="description" content="React application" />
            </Helmet>
          <Container className="container-fluid" style={{backgroundBlendMode: "difference"}}>
            <Col sm={10}>
          <h1 >
            Atelier créatif Ykopank & Upcycling
            </h1>
         
             
                Ykopank est un atelier créatif d'<b>assemblage et d'upcycling</b> de matières
                et d'idées en tout genre.
 
                <br /> <br />
                Les productions sont multiformes: restauration ou création
                d’objet et de meuble, sculpture et conception graphique. Le
                principe de base est l’assemblage et le surcyclage (upcycling)
                pour atteindre une pièce unique et originale.

                <br /> <br />Ce travail est le fruit de la rencontre entre des
                trouvailles matérielles et un esprit espiègle... ou peut
                répondre à une commande, intégrant vos idées et vos matières
                premières.
         
                </Col>     
          
          
       
  </Container>
</Jumbotron>
        
    );
  }
}

export default About;
