import React, { Component } from "react";
import Gallerie from "./Gallery";
import { Container, Row, Col , Button} from "react-bootstrap";
import ShareIt from "./ShareIt";
import OtherProjectSuggest from "./OtherProjectSuggest";
import { Helmet } from "react-helmet";


class ProjectDetails extends Component {

  render() {

    const {
      thumbnail,
      title,
      text_paragraphe_1,
      text_paragraphe_2,
      taille,
      prix,
      materiaux,
      detailURL,
    } = this.props.fiche;

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": title +' - artistic workshop Ykopank',
    "image": "https://www.ykopank-upcycling.com/"+thumbnail,
    "description": text_paragraphe_1 + " " + text_paragraphe_2,
    "brand": {
        "@type": "Person",
        "name": "Ykopank - upcycling"
    },
    "offers": {
        "@type": "Offer",
        "priceCurrency": "EUR",
        "price": prix.replace(/\D/g, ''),
        "url": "https://www.ykopank-upcycling.com/"+detailURL,
        "seller": {
            "@type": "Organization",
            "name": "Ykopank - upcycling"
        }
    },
    "additionalProperty": [
        {
            "@type": "PropertyValue",
            "name": "Medium",
            "value": materiaux
        },
        {
            "@type": "PropertyValue",
            "name": "Dimensions",
            "value":taille
        }
    ]
};
    

    return (
      
      <Container>
        <Helmet>
               <title>{this.props.fiche.title}</title>   
                <meta property="og:image" content={"https://www.ykopank-upcycling.com"+this.props.fiche.thumbnail} />
                <meta property="og:title" content={this.props.fiche.title} />
                <meta property="og:description" content={this.props.fiche.text_paragraphe_1} />
                <meta property="og:url" content={"https://www.ykopank-upcycling.com" + this.props.fiche.detailURL} />
                <meta property="og:type" content="article" />
                <meta charSet="utf-8" />
                <meta name="description" content={this.props.fiche.text_paragraphe_1} />
                <script type="application/ld+json">
                   {JSON.stringify(jsonLd)}
                </script>
        </Helmet>
        <Row style={{ marginTop: "5px" }}>
          <Col
            md={6}
            style={{
              color: "#181818",
              textTransform: "uppercase",
              border: "1px white #181818",
            }}
          >
            <Gallerie galerie={this.props.fiche.galerie} />
          </Col>

          <Col md={6}>
            <h1
              className="detail-project-title"
              style={{
                letterSpacing: "3px",
                textTransform: "uppercase",
                color: "#555",
                fontWeight: "bolder",
              }}
            >
              {this.props.fiche.title}
            </h1>
            <h6
              className="detail-project-subtitle"
              style={{ color: "#9a9a9a" }}
            >
              {this.props.fiche.subTitle}
            </h6>
            <p className="detail-project-text">
              {this.props.fiche.text_paragraphe_1}
            </p>
            <p className="detail-project-text">
              {this.props.fiche.text_paragraphe_2}
            </p>
            <p className="detail-project-materiaux">
              {this.props.fiche.materiaux && (
                <span>Matériaux : {this.props.fiche.materiaux}</span>
              )}
              {this.props.fiche.taille && (
                <p>Taille : {this.props.fiche.taille}</p>
              )}
            </p>

            { this.props.fiche.prix && <p className="detail-project-materiaux">
              Prix : {this.props.fiche.prix}              
            </p>
            }
            

           <p><Button style={{ textAlign: "center" , width:'100%', letterSpacing: "3px",
                textTransform: "uppercase",
                color: "#666",
                fontWeight:"bold"}} size="sm"
                href={`mailto:ykopank@gmail.com?subject=A%20propos%20de%20${this.props.fiche.title}`} 
                variant="warning" className="d-grid">Contactez l'auteur
            </Button></p>
            <hr/>


            <ShareIt
              url={`http://www.ykopank-upcycling.com${this.props.fiche.detailURL}`}
              title={this.props.fiche.title}
            />
            <br />
            <p
              style={{
                letterSpacing: "3px",
                textTransform: "uppercase",
                color: "#888",
                borderBottom: "1px #888 dotted",
              }}
            >
              Autres projets upcycling ykopank
            </p>
            <OtherProjectSuggest exept={this.props.fiche.title} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ProjectDetails;
