import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
//import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap"; // NavDropdown
//import { Nav, Row } from "react-bootstrap"; // NavDropdown
//import { LinkContainer } from "react-router-bootstrap";
//import ShareIt from "./ShareIt";

const Footer = () => {
  return (
    <div
      className="ml-auto fixed-bottom"
      style={{ color: "#888", fontSize: "12px", backgroundColor:"#111111" , textAlign:"right"}}
    >
       <Nav.Link to="/legal" href="legal" style={{display:"inline",color:"#aaaaaa"}}>
                Mentions Légales
              </Nav.Link>
              <b>©2020 Ykopank</b> assemblage - upcycling &nbsp;&nbsp;&nbsp;  
    </div>
  );
};

export default Footer;
