import React from "react";
import OtherProjectSuggest from "./OtherProjectSuggest";
import { Container , Row} from "react-bootstrap";
import { Helmet } from "react-helmet";
const NotFoud = () => {
    return (
        <Container style={{textAlign:"center",marginTop:"150px",color:"#515151"}}>
          <Helmet>
                <meta charSet="utf-8" />
                <title>404 - Ykopank - upcycling </title>
                <meta name="description" content="React application" />
            </Helmet>
            <hr />
            <h1 style={{fontSize:"4em"}}>Oulala !!  Erreur 404</h1>
            <h2>Vous vous êtes perdu sur Ykopank upcycling ???</h2> <br/><br/>   
            <h5 style={{textAlign:"center",}}>Continuez votre visite ;)</h5>     
            <Row style={{display:"bloc"}}>
               
                <div style={{border:"1px silver solid",padding:"20px",boxShadow:"0px 0px 5px 5px #efefef", borderRadius:"5px",textAlign:"center",marginTop:"50px",color:"#515151"}}>
                
                <p><OtherProjectSuggest /></p>
              </div>  
              </Row>
              <br /><br /><hr />
        </Container>
      );
}

export default NotFoud;