import img0 from "./0_writers-wall-color-ykopank-sculpture.jpg";
import img1 from "./1_writers-wall-color-ykopank-sculpture.jpg";


const WRITERS_GALERIE = [
  {
    original: img0,
    thumbnail: img0,
    description: "Writers Wall Color  Ykopank",
    originalTitle: "Writers Wall Color. Ykopank",
    originalAlt: "Upcycling - Writers Wall Color. Ykopank",
  },  
  {
    original: img1,
    thumbnail: img1,
    description: "Writers Wall Color. Ykopank",
    originalTitle: "Writers Wall Color. Ykopank",
    originalAlt: "Upcycling - Writers Wall Color. Ykopank",
  }
];

export default WRITERS_GALERIE;