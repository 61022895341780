//Importer image et thumbail
import MeubleSDB1 from "./meuble-salle-bain-1.jpg";

//Construire la galerrie (check doc pour détail des clés)
const MEUBLE_SDB_GALERIE = [
  {
    original: MeubleSDB1,
    thumbnail: MeubleSDB1,
    description: "Meuble Salle de Bain. Ykopank",
    originalTitle: "Upcyling - Meuble Salle de Bain. Ykopank",
  },
];
//Exporter la galerie
export default MEUBLE_SDB_GALERIE;
