//Importer image et thumbail
import Tabouret1 from "./tabouret-couleur-1.jpg";

//Construire la galerrie (check doc pour détail des clés)
const TABOURET_GALERIE = [
  {
    original: Tabouret1,
    thumbnail: Tabouret1,
    description: "Tabouret. Ykopank",
    originalTitle: "Tabouret. Ykopank",
    originalAlt: "Upcycling -Tabouret. Ykopank",
  },
];
//Exporter la galerie
export default TABOURET_GALERIE;
