//Importer image et thumbail
import Lampe1 from "./lampe-1-couleur.jpg";
import Lampe2 from "./lampe-2-couleur.jpg";

//Construire la galerrie (check doc pour détail des clés)
const LAMPE_GALERIE = [
  {
    original: Lampe1,
    thumbnail: Lampe1,
    description: "Lampe. Ykopank",
    originalTitle: "Lampe. Ykopank",
    originalAlt: "Upcycling -Lampe. Ykopank",
  },
  {
    original: Lampe2,
    thumbnail: Lampe2,
    description: "Lampe. Ykopank",
    originalTitle: "Lampe. Ykopank",
    originalAlt: "Upcycling -Lampe. Ykopank",
  },
];
//Exporter la galerie
export default LAMPE_GALERIE;
