//Importer image et thumbail
import Paulette1 from "./paulette-1-couleur.jpg";
import Paulette2 from "./paulette-2-couleur.jpg";

//Construire la galerrie (check doc pour détail des clés)
const PAULETTE_GALERIE = [
  {
    original: Paulette1,
    thumbnail: Paulette1,
    originalTitle: "Paulette",
    description: "Paulette .Ykopank",
    originalAlt: "Upcycling -Paulette la poulette",
  },
  {
    original: Paulette2,
    thumbnail: Paulette2,
    description: "Paulette .Ykopank",
    originalTitle: "Paulette",
    originalAlt: "Upcycling -Paulette la poulette",
  },
];
//Exporter la galerie
export default PAULETTE_GALERIE;
