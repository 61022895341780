//Importer image et thumbail
import Genealogie1 from "./Genealogie-Ykopank-1.png";
import Genealogie2 from "./Genealogie-Ykopank-2.png";

//Construire la galerrie (check doc pour détail des clés)
const GENEALOGIE_GALERIE = [
  {
    original: Genealogie1,
    thumbnail: Genealogie1,
    description: "Genealogie. Ykopank",
    originalTitle: "Genealogie. Ykopank",
    originalAlt: "Upcycling -Genealogie. Ykopank",
  },
  {
    original: Genealogie2,
    thumbnail: Genealogie2,
    description: "Genealogie. Ykopank",
    originalTitle: "Genealogie. Ykopank",
    originalAlt: "Upcycling -Genealogie. Ykopank",
  },
];
//Exporter la galerie
export default GENEALOGIE_GALERIE;
