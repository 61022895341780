import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, ListGroup } from "react-bootstrap";
import { FaImages } from "react-icons/fa";

class ProjectCardItem extends Component {

  style(){
    if(this.props.detail !== false ){
      return {border:"1px solid #efefef"
      , marginBottom:"15px"
      , paddingBottom:"15px"
      , borderRadius:"5px"}
    }else{
      return {border:"none"
      , marginBottom:"0px"
      , paddingBottom:"0px"
      , borderRadius:"0px"
      , backgroundColor:"#fff"}
    }
  }

  details (){
    if(this.props.detail !== false ){
      return <Card.Body style={{ padding: "5px"}} >  
      
      <ListGroup style={{ fontSize: "12px" }}>
      <Card.Title
        className="text-dark"
        style={{
          paddingLeft: "10px",
          fontFamily: " MULISH regular  ",
          fontSize: "1.5em",
          textAlign:"center",
          fontWeight:"bolder",
        }}
      >
        {this.props.card.title}
      </Card.Title>
      { this.props.card.shortStory && <ListGroup.Item >
          <Card.Text>{this.props.card.shortStory}</Card.Text>
        </ListGroup.Item>
      }      
      { this.props.card.taille && <ListGroup.Item>
          Taille : {this.props.card.taille}
        </ListGroup.Item>
      }
      { this.props.card.materiaux && <ListGroup.Item>
       {this.props.card.materiaux}
        </ListGroup.Item>
      }
        { this.props.card.prix && <ListGroup.Item>
          Prix : {this.props.card.prix}
        </ListGroup.Item>
        }
        <ListGroup.Item style={{padding:"0px",paddingLeft:"5px",color:"#454545"}}>
        
        <LinkContainer to={this.props.card.detailURL}  style={{color:"#454545"}}  >
          <Nav.Link
            to={this.props.card.detailURL}
            href={this.props.card.detailURL} 
            style={{color:"#454545"}}              
          ><FaImages/>&nbsp;Plus d'info ...</Nav.Link>
        </LinkContainer>     
        </ListGroup.Item>
      </ListGroup>
    </Card.Body>

    }
  }

  borderImg(){
    if(this.props.detail !== false )
      return {borderRadius:"5px"}
    return {borderRadius:"0px"}
  }

  link(){
    
      return <LinkContainer to={this.props.card.detailURL}>
          <Nav.Link
            to={this.props.card.detailURL}
            href={this.props.card.detailURL}
          >
            <Card.Img variant="top" 
              src={`${this.props.card.original}`} 
              style={this.borderImg()} 
              alt={`Upcycling ${this.props.card.title}`}/>
          </Nav.Link>
        </LinkContainer>
    
  }

  cardBg (){
    if(this.props.detail !== false ){
      return "bg-light text-dark" 
    }
  }

  render() {
    return (
      <Card className={`${this.cardBg()} embed-responsive`}       
       style={this.style()}>
        {this.link()}
        {this.details()}        
      </Card>
    );
  }
}

export default ProjectCardItem;
