//Importer image et thumbail
import Paulette from "./Paulette. YKOPANK.jpg";
import PAULETTE_GALERIE from "../Paulette_galerie/Paulette_config";
import LegoutDegoupille from "./L'égout et les goupilles. YKOPANK.jpg";
import EGOUT_GALERIE from "../Egout_galerie/Egout_config";
import Porteur from "./Le porteur de signes. YKOPANK.jpg";
import PORTEUR_GALERIE from "../Porteur_galerie/Porteur_config";
import TypoRobot from "./TypoRobot (mr Capital). YKOPANK.jpg";
import TYPOROBOT_GALERIE from "../Typorobot_galerie/Typorobot_config";
//MEUBLE ET OBJECT
import Tabouret from "./tabouret-couleur.jpg";
import TABOURET_GALERIE from "../Tabouret_galerie/Tabouret_config";
import Meuble_sdb from "./meuble-salle-bain-1.jpg";
import MEUBLE_SDB_GALERIE from "../Meuble_sdb_galerie/Meuble_SDB_config";
import Lampe from "./lampe-1-couleur.jpg";
import LAMPE_GALERIE from "../Lampe_galerie/Lampe_config";
import Louypokanp from "./louykopank-XIV-1.jpg";
import LOUYPOKANPE_GALERIE from "../Louypokanp_galerie/Louypokanp";
import Banquette from "./Banquette-Sprague-1.jpg";
import BANQUETTE_GALERIE from "../Banquette_galerie/Banquette_config";
import Banquette2 from "./banquette-2-sprague-1.jpg";
import BANQUETTE_GALERIE_2 from "../Sprague_2/Banquette2_config";

import Mandarin from "./le-Madarin-Ykopank-1.png";
import MANDARIN_GALERIE from "../Mandarin/Mandarin_config";

import Chevalet from "./les-Chevalets-Ykopank-1.png";
import CHEVALET_GALERIE from "../Chevalets/Chevalet_config";

import Genealogie from "./Généalogie-Ykopank-1.png";
import GENEALOGIE_GALERIE from "../Genealogie/Genealogie_config";

import Paquito from "./Paquito.Moaï-Ykopank-1.png";
import PAQUITO_GALERIE from "../Paquito/Paquito_config";

import MagueritteRameau from "./Magueritte-Rameau-ykopank-sculpture-1.png"; 
import MAGUERITTE_GALERIE from "../Marguerite_gallerie/Magueritte-Rameau_config";

import Lalineart from "./Lalineart.contemporary-Ykopank-1.png"; 
import Lalineart_GALERIE from "../Lalineart_gallerie/Lalineart_config";

import Odeleite from "./Ykopank-Odeleite-1.jpg"; 
import ODELEITE_GALERIE from "../Odeilte/Odeleite_config";

import YKOBOITE from "./Ykopank-Boite-Yko-1.jpg"; 
import YkoBoite_GALERIE from "../Yko_boite/Yko-Boite_config";

import TABLE_SCANDINAVE from "./table-scandinave-ykopank-1.jpg"
import TABLE_SCANDINAVE_GALERIE from "../Table_scandinave/table-scandinave_config";

import coiffeuse from "./1_Coiffeuse-ykopank-meuble-biais.jpg"
import COIFFEUSE_GALERIE from "../Coiffeuse/Coiffeuse_config";

import writers from "./0_writers-wall-color-ykopank-sculpture.jpg";
import WRITERS_GALERIE from "../Writers_galerie/writers_config";



const GridSet = [
  {
    categorie: "meuble",
    original: YKOBOITE,
    thumbnail: YKOBOITE,
    title: "Yko Boîte",
    subTitle: "Surcyclage (Upcycling).",
    text_paragraphe_1:
      "Boite de rangement en bois dotée d'un motif en incrustation sur le dessus et deux compartiments de rangement modulables. Elle dispose également de 4 pieds coniques en plastique de 1,5 cm",
    text_paragraphe_2:"",
    shortStory: "",
    taille: "H: 12cm L: 28cm P: 21cm",
    prix: "110€",
    materiaux: "Bois, laiton",
    materiaux_full: "",
    detailURL: "/Yko-boite.html",
    galerie: YkoBoite_GALERIE,
},
{
  categorie: "meuble",
  original: TABLE_SCANDINAVE,
  thumbnail: TABLE_SCANDINAVE,
  title: "Table scandinave modulable",
  subTitle: "Table scandinave - surcyclage (Upcycling).",
  text_paragraphe_1:"Table scandinave modulable offrant une atmosphère chaleureuse grâce à son design épuré ainsi qu'aux bois qui la compose.",
  text_paragraphe_2:"",
  shortStory: "",
  taille: "H: 45cm/55cm L: 120cm P: 60cm",
  prix: "Vendu",
  materiaux: "Teck et Chêne, acier.",
  materiaux_full: "",
  detailURL: "/table-scandinave.html",
  galerie: TABLE_SCANDINAVE_GALERIE,
},
{
  categorie: "sculpture",
  original: Odeleite,
  thumbnail: Odeleite,
  title: "L'Odeleite",
  subTitle: "Sculpture, (upcycling).",
  text_paragraphe_1:
    "Découvrez \"L'Odeleite\" par Ykopank, une sculpture design inspirée par la rivière Odeleite au Portugal, capturant la forme d'un Dragon Bleu, offrant une fusion artistique entre nature, imagination et art contemporain.",
  text_paragraphe_2:"La rivière Odeleite dont la particularité est sa forme de Dragon Bleu est réputée pour ses courbes sinueuses et la beauté de ses paysages, cette création artistique offre une représentation imaginative de la rivière, interprétant ses méandres et ses caractéristiques géographiques. Elle capture la puissance et la magie de cet environnement naturel, tout en lui conférant une dimension fantastique et symbolique à travers la figure du dragon.",
  shortStory: "Découvrez \"L'Odeleite\" par Ykopank, une sculpture design inspirée par la rivière Odeleite au Portugal, capturant la forme...",
  taille: "H:28 cm L:36 cm",
  prix: "1100€",
  materiaux: "",
  materiaux_full: "",
  detailURL: "/Lodeleite.html",
  galerie: ODELEITE_GALERIE,
},
  {
    categorie: "sculpture",
    original: Lalineart,
    thumbnail: Lalineart,
    title: "L'alinéart",
    subTitle: "Sculpture, (upcycling).",
    text_paragraphe_1:
      "Observez cette sculpture mystérieuse \"L'Alinéart Contemporary\", une sculpture en forme de point d'interrogation inspirée par la série d'animation \"La Linea\" d'Osvaldo Cavandoli. Symbolisant la trajectoire individuelle dans la vie, cette œuvre met en avant la nécessité de défendre sa ligne de vie, soulignant la valeur de la résilience et de l'adaptabilité.",
    text_paragraphe_2:"Découvrez cette création artistique captivante, fusionnant l'esprit ludique de l'animation avec une réflexion profonde sur la vie et l'expression personnelle.",
    shortStory: "Observez cette sculpture mystérieuse \"L'Alinéart Contemporary\", une sculpture en forme de point d'interrogation inspirée par la série d'animation \"La Linea\" d'Osvaldo Cavandoli. Symbolisant ...",
    taille: "H:28 cm L:10 cm",
    prix: "900€",
    materiaux: "Bois, Métal",
    materiaux_full: "",
    detailURL: "/Lalineart.html",
    galerie: Lalineart_GALERIE,
},
  {
    categorie: "sculpture",
    original: Paquito,
    thumbnail: Paquito,
    title: "Paquito",
    subTitle: "Assemblage, surcyclage (upcycling).",
    text_paragraphe_1:
      "Explorez Paquito, une sculpture contemporaine rendant hommage aux Moaï de l'île de Pâques, également connue sous le nom de Rapa Nui. Située dans la Polynésie chilienne, cette création artistique offre une interprétation moderne des figures monumentales datant du XIIIe au XVe siècle.",
    text_paragraphe_2:
      "Mesurant entre 2 et 9 mètres de hauteur, les Moaï se distinguent par leur taille imposante, leur style caractéristique, et des détails expressifs tels que des yeux en corail blanc et des iris en obsidienne noire ou rouge. Découvrez cette œuvre, témoignage artistique contemporain d'une riche histoire polynésienne.",
    shortStory:
      "Explorez Paquito, une sculpture figurative rendant hommage aux Moaï de l'île de Pâques, également connue sous le nom de Rapa Nui. Située dans la Polynésie chilienne, cette création...",
    taille: "H:60 cm L:9.5 cm",
    prix: "1700€",
    materiaux: "",
    materiaux_full: "",
    detailURL: "/Paquito.html",
    galerie: PAQUITO_GALERIE,
  },
   {
    categorie: "sculpture",
    original: Genealogie,
    thumbnail: Genealogie,
    title: "Généalogie",
    subTitle: "Assemblage, surcyclage (upcycling).",
    text_paragraphe_1:"Explorez \"L'efflorescence des pensées\", une œuvre évoquant une métamorphose constante, du liquide à l'aérien, de la terre aux étoiles. Ce voyage artistique transcende du réveil initial à l'épanouissement final, connectant nos racines profondes aux liens subtils qui nous unissent.",
    text_paragraphe_2:
      "Appréciez l'invisible contribuant à la splendeur de la vie, tissant des liens entre passé et devenir. Malgré des chemins uniques, découvrez une convergence dans ces trajectoires, une similitude dans la façon dont chaque voyage se déploie. Plongez dans cette expérience artistique, riche en symbolisme et en exploration de la vie.",
    shortStory:
      "Explorez \"L'efflorescence des pensées\", une œuvre évoquant une métamorphose constante, du liquide à l'aérien, de la terre aux étoiles. Ce voyage artistique transcende du réveil initial à l'épanouissement final, connectant...",
    taille: "H:39 cm L:26 cm",
    prix: "2400€",
    materiaux: "",
    materiaux_full: "",
    detailURL: "/Genealogie.html",
    galerie: GENEALOGIE_GALERIE,
  },
  {
    categorie: "sculpture",
    original: Mandarin,
    thumbnail: Mandarin,
    title: "Le Mandarin",
    subTitle: "Assemblage, surcyclage (upcycling).",
    text_paragraphe_1:
      "Explorez l'histoire captivante du \"Mandarin\", symbole des hauts fonctionnaires de l'administration impériale chinoise, détenant d'importants pouvoirs administratifs. Ces érudits, reconnus pour leur éducation de haut niveau, acquise par le biais d'examens impériaux rigoureux, ont exercé une influence considérable dans diverses régions de la Chine impériale.",
    text_paragraphe_2:
      "Le système des mandarins, basé sur le mérite et l'éducation, a offert des opportunités aux individus talentueux pour gravir les échelons de la fonction publique impériale. Ces hauts fonctionnaires ont joué un rôle crucial dans la formation de l'administration et de la politique de la Chine impériale pendant plusieurs siècles. Explorez leur impact durable à travers cette plongée dans l'histoire impériale chinoise.",
    shortStory:
      "Explorez l'histoire captivante du \"Mandarin\", symbole des hauts fonctionnaires de l'administration impériale chinoise...",
    taille: "H:13 cm L:4 cm ",
    prix: "65€",
    materiaux: "",
    materiaux_full: "",
    detailURL: "/Mandarin.html",
    galerie: MANDARIN_GALERIE,
  },
  {
    categorie: "sculpture",
    original: Chevalet,
    thumbnail: Chevalet,
    title: "Les chevalets",
    subTitle: "Assemblage, surcyclage (upcycling).",
    text_paragraphe_1:
"Retrouvez les sculptures upcycling, les \"Chevalets\", des outils polyvalents incontournables dans l'art, l'architecture, l'imprimerie, l’astronomie, et bien d'autres domaines, révélant une polyvalence allant de la noblesse à des contextes plus sombres.",
    text_paragraphe_2:
      "Ces supports artistiques servent non seulement pour les toiles en art, mais aussi pour le maintien des plans en architecture et l'exposition des échantillons en botanique. Malheureusement, l'histoire a également révélé leur utilisation dans des pratiques de torture. La polyvalence de ces chevalets démontre leur adaptation remarquable à une diversité de tâches, des plus nobles aux plus sombres. Une exploration captivante de leur rôle à travers différentes disciplines.",
    shortStory:
      "Retrouvez les sculptures upcycling, les \"Chevalets\", des outils polyvalents incontournables dans l'art, l'architecture, l'imprimerie, l’astronomie,...",
    taille: "H:16 cm L:8.5 cm",
    prix: "45 €/pièce",
    materiaux: "",
    materiaux_full: "",
    detailURL: "/Les-chevalets.html",
    galerie: CHEVALET_GALERIE,
  },
  {
    categorie: "sculpture",
    original: Paulette,
    thumbnail: Paulette,
    title: "Paulette",
    subTitle: "Assemblage, surcyclage (upcycling).",
    text_paragraphe_1:
      "Entrez dans l'univers de Paulette, une sculpture unique représentant une nouvelle espèce d'oiseaux terrestres, fusionnant histoire ancienne et créativité contemporaine. Paulette, affiliée à la famille des phasianidés, offre une perspective intrigante sur les relations humain-animal, avec des traces de domestication remontant à 5 000 ans avant J.-C.",
    text_paragraphe_2:
    "Plongez dans cette œuvre d'assemblage, inscrite dans une démarche de surcyclage (upcycling), offrant une réflexion profonde sur l'impact des relations anciennes sur l'histoire et la culture humaine.",
    shortStory:
      "Entrez dans l'univers de Paulette, une sculpture unique représentant une nouvelle espèce d'oiseaux terrestres, fusionnant histoire ancienne et créativité contemporaine. Paulette, affiliée à ...",
    taille: "38 cm",
    prix: "1900€",
    materiaux: "Acier, verre, polyéthylène, bois, inox, laiton...",
    materiaux_full: "",
    detailURL: "/Paulette.html",
    galerie: PAULETTE_GALERIE,
  },
  {
    categorie: "sculpture",
    title: "L'égout et les goupilles",
    subTitle: "Assemblage, surcyclage (upcycling).",
    taille: "15.5 cm",
    prix: "70€",
    materiaux: "Pierre, acier.",
    materiaux_full: "",
    text_paragraphe_1: "Découvrez \"L'Égout et les Goupilles\", une œuvre artistique assemblée à partir d'un pavé parisien, d'une goupille de grenade et reposant sur un socle en métal urbain.",
    text_paragraphe_2:
      "Cette création intrigante interpelle sur la ville, ses cicatrices, ses éléments conflictuels et ses transformations, le tout présenté de manière artistique et provocante. Explorez une nouvelle perspective sur la réinterprétation d'objets du quotidien pour exprimer des idées et des concepts plus vastes, grâce à l'utilisation créative de matériaux recyclés.",
    original: LegoutDegoupille,
    thumbnail: LegoutDegoupille,
    shortStory: "Découvrez \"L'Égout et les Goupilles\", une œuvre artistique assemblée à partir d'un pavé parisien,...",
    detailURL: "/L-egout-et-les-goupilles.html",
    galerie: EGOUT_GALERIE,
  },

  {
    categorie: "sculpture",
    original: Porteur,
    thumbnail: Porteur,
    title: "Le porteur de signes",
    subTitle: "Assemblage, surcyclage (upcycling).",
    materiaux: "Acier, inox, laiton, verre, bois...",
    materiaux_full: "",
    text_paragraphe_1:
"Découvrez le \"Porteur de Signes\", une sculpture allant au-delà du rôle traditionnel du messager. Ce dépositaire de symboles et de messages vous invite à plonger dans les profondeurs de la connaissance. Découvrez son pouvoir de donner vie aux idées, d'éveiller les consciences et de catalyser le changement à travers les signes qu'il transporte.",
    text_paragraphe_2: "Le porteur de signes devient un interprète du monde, un guide incitant à explorer les profondeurs de la connaissance, à remettre en question nos perceptions et à révéler la beauté cachée dans les symboles et les idées. Cette œuvre artistique offre une expérience de réflexion.",
    prix: "Vendu 200€",
    shortStory: "Découvrez le \"Porteur de Signes\", une sculpture allant au-delà du rôle traditionnel du messager. Ce dépositaire de symboles et de messages vous invite...",
    taille: "23 cm",
    detailURL: "/Le-porteur-de-signes.html",
    galerie: PORTEUR_GALERIE,
  },
  {
    categorie: "sculpture",
    original: TypoRobot,
    thumbnail: TypoRobot,
    title: "TypoRobot",
    subTitle: "Assemblage, surcyclage (upcycling).",
    materiaux: "Bois, acier.",
    materiaux_full: "",
    text_paragraphe_1:
      "Découvrez \"TypoRobot\" alias Mrs. Capital, une sculpture unique fusionnant l'artisanat des caractères d'imprimerie avec la créativité contemporaine. Intégrant une armature en acier et un sillet de violoncelle en ébène, cette œuvre incarne la rencontre entre technologie et tradition, passé et présent, mécanique et expression artistique.",
    text_paragraphe_2: "Une création contemporaine explorant les thèmes de la technologie, de la créativité et de l'histoire de l'impression.",
    prix: "1400€",
    shortStory: "Découvrez \"TypoRobot\" alias Mrs. Capital, une sculpture unique fusionnant l'artisanat des caractères d'imprimerie avec la créativité contemporaine. Intégrant une armature en acier...",
    taille: "32,5 cm",
    detailURL: "/TypoRobot.html",
    galerie: TYPOROBOT_GALERIE,
  },
  {
    categorie: "meuble",
    original: Tabouret,
    thumbnail: Tabouret,
    title: "Tabouret",
    subTitle: "surcyclage (upcycling).",
    materiaux: "Bois, plastique, acier, coquilles d'oeufs.",
    materiaux_full: "",
    text_paragraphe_1:
      "Buse d’évacuation, planches à découper, tringle à rideau,laque coquilles d’oeufs.",
    text_paragraphe_2: "",
    prix: "Vendu",
    shortStory: "Tabouret pivotant",
    taille: "80 cm",
    detailURL: "/tabouret.html",
    galerie: TABOURET_GALERIE,
  },
  {
    categorie: "meuble",
    original: Meuble_sdb,
    thumbnail: Meuble_sdb,
    title: "Meuble suspendu",
    subTitle: "Création, surcyclage (upcycling).",
    materiaux: "Bois, métal.",
    materiaux_full: "",
    text_paragraphe_1:
      "Meuble de salle de bain, lames de parquet massif, lasure bois.",
    text_paragraphe_2: "",
    prix: "Vendu",
    shortStory: "Meuble salle de bain",
    taille: "H:82 P:22 L:72 cm",
    detailURL: "/meuble-salle-de-bain-suspendu.html",
    galerie: MEUBLE_SDB_GALERIE,
    desabled:true
  },
  {
    categorie: "meuble",
    original: Lampe,
    thumbnail: Lampe,
    title: "Lampe",
    subTitle: "Restauration, modifications",
    materiaux: "Métal.",
    materiaux_full: "",
    text_paragraphe_1:
      "Métal, peinture rouge pigments haut de gamme, puissance 40w.",
    text_paragraphe_2: "",
    prix: "Vendu",
    shortStory: "Lampe d’appoint.",
    taille: "42 cm",
    detailURL: "/lampe.html",
    galerie: LAMPE_GALERIE,
  },
  {
    categorie: "meuble",
    original: Louypokanp,
    thumbnail: Louypokanp,
    title: "Console",
    subTitle: "Transformation, création.",
    materiaux: "Bois, céramique.",
    materiaux_full: "",
    text_paragraphe_1:
      "Meuble en bois, couleur gris anthracite et lasure incolore, carrelage ancien, tiroir coulissant.",
    text_paragraphe_2: "",
    prix: "250€",
    shortStory: "Console Louykopank XIV",
    taille: "H:77 cm P:37,5 cm L:49,5 cm",
    detailURL: "/louykopank-XIV.html",
    galerie: LOUYPOKANPE_GALERIE,
  },
  {
    categorie: "meuble",
    original: Banquette,
    thumbnail: Banquette,
    title: "Banquette de métro",
    subTitle: "Restauration.",
    materiaux: "Bois, acier.",
    materiaux_full: "",
    text_paragraphe_1:
      "Banquette de Métro de wagon Sprague 2ème classe. Sprague Thomson est le nom des premières rames entièrement en métal du métro de Paris, que l'on appellera familièrement Sprague. Ces rames, ont longtemps symbolisé le métro de Paris et ont marqué trois générations de Parisiens. Après la catastrophe dans les stations Couronnes et Ménilmontant en 1903, la Compagnie du chemin de fer métropolitain de Paris (CMP) cherche à mettre au point un matériel roulant fiable et sûr.",
    text_paragraphe_2:
      "L'équipement Thomson Multiple est le plus souvent adopté, mais il est complexe. À l'inverse, le Sprague venu d'Amérique ne convainc pas. À compter de 1908 apparaissent les rames Sprague Thomson. Synthèse des systèmes précédents et l'utilisation de procédé de commande des moteurs inventé par l'industriel américain Frank J. Sprague. (séries 500-600 puis 800-1000) dont les dernières ne seront retirées du service qu’en 1983.",
    prix: "Vendu",
    shortStory: "Banquette Sprague",
    taille: "",
    detailURL: "/banquette-sprague.html",
    galerie: BANQUETTE_GALERIE,
  },
  {
    categorie: "meuble",
    original: Banquette2,
    thumbnail: Banquette2,
    title: "Banquette de métro n°2",
    subTitle: "Restauration.",
    materiaux: "Bois, acier.",
    materiaux_full: "",
    text_paragraphe_1:
      "Frank Julian Sprague (1857-1934). Né à Milford, Connecticut, Etats-Unis d'Amérique et diplomé de l'Académie Navale en 1878. Il travailla pour la firme de Thomas Edison à partir de mai 1883 jusqu'en 1884 date à laquelle il décida de créer sa propre societé : Sprague Electric Railway and Motor CO. Il est aussi le pionnier de l'ascenseur électrique dont le premier sera mis en service à New York City en 1893 (Il revendra ce secteur d'activité à une autre société qui plus tard deviendra OTIS).",
      text_paragraphe_2:
      "Son invention la plus importante a été le système de contrôle de traction à unités-multiples qui a permis la conception de rames avec plusieurs éléments moteurs se comportant comme une seule unité. Sprague-Thomson est le surnom donné aux premières rames entièrement métalliques du métro de Paris. Ce type de matériel extrêmement fiable et robuste est resté en service pendant 75 ans, de 1908 à 1983.",
    prix: "1500€ - vendu",
    shortStory: "Banquette Sprague",
    taille: "",
    detailURL: "/restauration-banquette-sprague-2.html",
    galerie: BANQUETTE_GALERIE_2,
  },
  {
    categorie: "sculpture",
    original: MagueritteRameau,
    thumbnail: MagueritteRameau,
    title: "Mme Magueritte Rameau",
    subTitle: "Sculpture, (upcycling).",
    text_paragraphe_1:"Découvrez \"Mme Magueritte Rameau (La poule)\", une sculpture luminaire rendant hommage à Jean-Philippe Rameau et René Magritte, offrant une symbiose artistique entre le XVIIIe et le XXe siècle.",
    text_paragraphe_2:"Explorez l'influence de Rameau, innovateur musical de la musique baroque, avec des œuvres révolutionnaires comme \"Castor et Pollux\" et \"Les Indes galantes\". Décryptez la redéfinition artistique de Magritte, célèbre pour ses peintures énigmatiques et ses images surréalistes, telle que \"La trahison des images\". Malgré des médiums artistiques différents, Rameau et Magritte partagent une passion pour l'expérimentation et l'innovation, défiant les conventions de leur époque. Plongez dans cette fusion artistique captivante qui continue de fasciner et d'inspirer aujourd'hui.",
    shortStory:"Découvrez \"Mme Magueritte Rameau (La poule)\", une sculpture luminaire rendant hommage à Jean-Philippe Rameau et René Magritte...",
    taille: "46 cm L:19 cm",
    prix: "1600€",
    materiaux: "Métal, époxy",
    materiaux_full: "",
    detailURL: "/Magueritte-Rameau.html",
    galerie: MAGUERITTE_GALERIE,
},
{
  categorie: "sculpture",
  title: "Writers Wall Color",
  subTitle: "Assemblage, surcyclage (upcycling).",
  taille: "H: 20cm, L:7cm",
  prix: "240€",
  materiaux: "Béton, brique",
  materiaux_full: "",
  text_paragraphe_1: "Découvrez une sculpture dédiée à l'art urbain \"Writers Wall Color\" par Ykopank, une bombe de peinture en béton et en brique qui célèbre le mouvement des writers originaire de Philadelphie. Ce mouvement artistique, ancré dans la culture urbaine, met en lumière l'expression créative et souvent contestataire à travers l'art du graffiti.",
  text_paragraphe_2:
    "Un hommage à l'esprit rebelle et à l'audace artistique des writers et la reconnaissance de leur contribution majeure à la redéfinition de la créativité urbaine ainsi qu'à l'évolution de l'art contemporain.",
  original: writers,
  thumbnail: writers,
  shortStory: "Découvrez \"Writers Wall Color\" par Ykopank, une bombe de peinture en béton et en brique...",
  detailURL: "/writers-Wall-Color.html",
  galerie: WRITERS_GALERIE,
},
{
  categorie: "meuble",
  title: "Coiffeuse",
  subTitle: "Assemblage, surcyclage (upcycling).",
  taille: "H:75 cm ( 1M25 avec miroir) L:90 cm P:40 cm",
  prix: "360€",
  materiaux: "Bois",
  materiaux_full: "",
  text_paragraphe_1: "Apparue au XVIIIe siècle en France, la coiffeuse incarne raffinement et élégance. Dotée de miroirs encadrés artistiquement, elle était un élément essentiel des chambres à coucher de l'aristocratie. Au XIXe siècle, son design a évolué avec l'Art Nouveau et l'Art Déco, devenant un incontournable.",
  text_paragraphe_2:
    "Ykopank redonne vie à ce meuble, invitant à redécouvrir son charme intemporel dans le monde contemporain.",
  original: coiffeuse,
  thumbnail: coiffeuse,
  shortStory: "Apparue au XVIIIe siècle en France, la coiffeuse incarne raffinement et élégance. Dotée de miroirs...",
  detailURL: "/coiffeuse.html",
  galerie: COIFFEUSE_GALERIE,
}
];
export default GridSet;
