//Importer image et thumbail
import TypoRobot1 from "./typo-robot-1-couleur.jpg";
import TypoRobot2 from "./typo-robot-2-couleur.jpg";

//Construire la galerrie (check doc pour détail des clés)
const TYPOROBOT_GALERIE = [
  {
    original: TypoRobot1,
    thumbnail: TypoRobot1,
    originalTitle: "Typo-robot (Mr. Capital)",
    description: "Typo-robot (Mr. Capital)",
    originalAlt: "Upcycling -Typo-robot (Mr. Capital)",
  },
  {
    original: TypoRobot2,
    thumbnail: TypoRobot2,
    originalTitle: "Typo-robot (Mr. Capital)",
    description: "Typo-robot (Mr. Capital)",
    originalAlt: "Upcycling -Typo-robot (Mr. Capital)",
  },
];
//Exporter la galerie
export default TYPOROBOT_GALERIE;
