//Importer image et thumbail
import Chevalet1 from "./les-Chevalets-Ykopank-1.png";
import Chevalet2 from "./les-Chevalets-Ykopank-2.png";
import Chevalet3 from "./les-Chevalets-Ykopank-3.png";

//Construire la galerrie (check doc pour détail des clés)
const CHEVALET_GALERIE = [
  {
    original: Chevalet1,
    thumbnail: Chevalet1,
    description: "Chevalet. Ykopank",
    originalTitle: "Chevalet. Ykopank",
    originalAlt: "Upcycling -Chevalet. Ykopank",
  },
  {
    original: Chevalet2,
    thumbnail: Chevalet2,
    description: "Chevalet. Ykopank",
    originalTitle: "Chevalet. Ykopank",
    originalAlt: "Upcycling -Chevalet. Ykopank",
  },
  {
    original: Chevalet3,
    thumbnail: Chevalet3,
    description: "Chevalet. Ykopank",
    originalTitle: "Chevalet. Ykopank",
    originalAlt: "Upcycling -Chevalet. Ykopank",
  },
];
//Exporter la galerie
export default CHEVALET_GALERIE;
