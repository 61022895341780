import img0 from "./1_Coiffeuse-ykopank-meuble-biais.jpg";
import img1 from "./0_Coiffeuse-ykopank-meuble-biais.jpg";
import img2 from "./2_Coiffeuse-ykopank-meuble-biais.jpg";

const COIFFEUSE_GALERIE = [
  {
    original: img0,
    thumbnail: img0,
    description: "Coiffeuse Ykopank",
    originalTitle: "Coiffeuse. Ykopank",
    originalAlt: "Upcycling - Coiffeuse. Ykopank",
  },  
  {
    original: img1,
    thumbnail: img1,
    description: "Coiffeuse. Ykopank",
    originalTitle: "Coiffeuse. Ykopank",
    originalAlt: "Upcycling - Coiffeuse. Ykopank",
  },  
  {
    original: img2,
    thumbnail: img2,
    description: "Coiffeuse. Ykopank",
    originalTitle: "Coiffeuse. Ykopank",
    originalAlt: "Upcycling - Coiffeuse. Ykopank",
  }
];

export default COIFFEUSE_GALERIE;