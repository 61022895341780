import MagueritteRameau1 from "./Magueritte-Rameau-ykopank-sculpture-1.png";
import MagueritteRameau2 from "./Margueritte-Rameau-ykopank-sculpture-2.png";
import MagueritteRameau3 from "./Margueritte-Rameau-ykopank-sculpture-3.png";
import MagueritteRameau4 from "./Magueritte-Rameau-ykopank-sculpture-4.png";

const MAGUERITTE_GALERIE = [
  {
    original: MagueritteRameau1,
    thumbnail: MagueritteRameau1,
    description: "Magueritte-Rameau. Ykopank",
    originalTitle: "Magueritte-Rameau. Ykopank",
    originalAlt: "Upcycling -Magueritte-Rameau. Ykopank",
  },
  {
    original: MagueritteRameau2,
    thumbnail: MagueritteRameau2,
    description: "Magueritte-Rameau. Ykopank",
    originalTitle: "Magueritte-Rameau. Ykopank",
    originalAlt: "Upcycling -Magueritte-Rameau. Ykopank",
  },
  {
    original: MagueritteRameau3,
    thumbnail: MagueritteRameau3,
    description: "Magueritte-Rameau. Ykopank",
    originalTitle: "Magueritte-Rameau. Ykopank",
    originalAlt: "Upcycling -Magueritte-Rameau. Ykopank",
  },
  {
    original: MagueritteRameau4,
    thumbnail: MagueritteRameau4,
    description: "Magueritte-Rameau. Ykopank",
    originalTitle: "Magueritte-Rameau. Ykopank",
    originalAlt: "Upcycling -Magueritte-Rameau. Ykopank",
  }
];

export default MAGUERITTE_GALERIE;