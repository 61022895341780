import React, { Component } from "react";
import ListeCategorie from "./ListeCategorie";
import About from "./About";
import GridSet from "../Medias/Gallery_0/GridSet";
import { Helmet } from "react-helmet";

class ListeProjects extends Component {
  render() {

    var products = GridSet;
    function generateJSONLD(products) {
      var jsonLD = {
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": []
      };
  
      let i = 1; // Initialisation de la variable en dehors de la boucle
  
      products.forEach(function(product) {
          var listItem = {
              "@type": "ListItem",
              "position": i++, // Utilisation de i puis incrémentation
              "url": "https://www.ykopank-upcycling.com" + product.detailURL
          };
          jsonLD.itemListElement.push(listItem);
      });
  
      return JSON.stringify(jsonLD, null, 2); // Convertit l'objet JSON en chaîne formatée
  }
  
    return (
     
      <div> 
        <Helmet>
        
        <script type="application/ld+json">
          {generateJSONLD(products)}
        </script>
        </Helmet>
        <About />
        <ListeCategorie  maxDisplay="5" categorie="sculpture"/>
        <ListeCategorie  bg="#cfcfcf" maxDisplay="5" categorie="meuble"/>       
      </div>
    );
  }
}

export default ListeProjects;
