//Importer image et thumbail
import Porteur1 from "./le-porteur-de-signe-1-coule.jpg";
import Porteur2 from "./le-porteur-de-signe-2-coule.jpg";
import Porteur3 from "./le-porteur-de-signe-3-coule.jpg";
import Porteur4 from "./le-porteur-de-signe-4-coule.jpg";

//Construire la galerrie (check doc pour détail des clés)
const PORTEUR_GALERIE = [
  {
    original: Porteur1,
    thumbnail: Porteur1,
    originalTitle: "Le porteur de signe",
    description: "Le porteur de signe .Ykopank",
    originalAlt: "Upcycling -le porteur de signe",
  },
  {
    original: Porteur2,
    thumbnail: Porteur2,
    originalTitle: "Le porteur de signe",
    description: "Le porteur de signe .Ykopank",
    originalAlt: "Upcycling -le porteur de signe",
  },

  {
    original: Porteur3,
    thumbnail: Porteur3,
    description: "Le porteur de signes. Ykopank",
    originalTitle: "Le porteur de signe",
    originalAlt: "Upcycling -le porteur de signe",
  },
  {
    original: Porteur4,
    thumbnail: Porteur4,
    originalTitle: "Le porteur de signe",
    description: "Le porteur de signe .Ykopank",
    originalAlt: "Upcycling -le porteur de signe",
  },
];
//Exporter la galerie
export default PORTEUR_GALERIE;
