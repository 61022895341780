//Importer image et thumbail
import Banquette1 from "./banquette-2-sprague-1.jpg";
import Banquette2 from "./banquette-2-sprague-2.jpg";

//Construire la galerrie (check doc pour détail des clés)
const EGOUT_GALERIE = [
  {
    original: Banquette1,
    thumbnail: Banquette1,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
  {
    original: Banquette2,
    thumbnail: Banquette2,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
];
//Exporter la galerie
export default EGOUT_GALERIE;
