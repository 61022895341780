import React, { Component } from "react";
import GridSet from "../Medias/Gallery_0/GridSet.js";
import CardGroup from 'react-bootstrap/CardGroup';
import { Container } from "react-bootstrap";
import ProjectCardItem from "./ProjectCardItem";
import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";
import Shuffle from "./ShuffleObj";
import { Helmet } from "react-helmet";
import Row from 'react-bootstrap/Row';


class ListeCategorie extends Component {
  render() {
    const maxDisplay = this.props.maxDisplay || 1000;
    console.log(maxDisplay);
    let bg = this.props.bg || "#fff";
    return (
      <Jumbotron
        style={{ fontFamily: "MONTSERRAT regular", backgroundColor: `${bg}` }}
        fluid
      >
        <Helmet>
                <meta charSet="utf-8" />
                <title>Ykopank - upcycling - liste des projets</title>
                <meta name="description" content="liste des projets de surcyclage de Ykopank" />
            </Helmet>
        <Container>
          <h1
            className="grid-categorie"
            style={{
              paddingLeft: "10px",
              letterSpacing: "5px",
              textTransform: "uppercase",
              color: "#555555",
              borderBottom: "1px #555555 solid",
              fontWeight: "bolder",
              borderLeft: "10px solid #555555",
            }}
          >
            <span style={{ letterSpacing: "3px", fontSize: "10px" }}>
              YKOPANK
            </span>{" "}
            {this.props.categorie}s
          </h1>

          <div style={{ marginTop: "15px" }}>
          <CardGroup >
            <Row xs={1} md={5} className="g-1">          
              {  
                  Shuffle(GridSet)
                  .filter((thumb) => thumb.categorie === this.props.categorie && thumb.desabled !== true)
                  .map((thumb, i) => {
                   
                        return (                                   
                           i<maxDisplay && <><ProjectCardItem key={i} card={thumb} /></>                        
                      );
                                                     
                  })
                }
            </Row>
          </CardGroup>

          </div>
          {maxDisplay !== 1000 && (
            <div style={{ marginTop: "15px" }}>
              <LinkContainer
                to={`/${this.props.categorie}s.html`}
                style={{ textAlign: "center" }}
              >
                <Nav.Link to={`/${this.props.categorie}s.html`}>
                  <Button variant="secondary" style={{ width: "80%" }}>
                    Cliquez ici pour voir toutes les créations de la catégorie {this.props.categorie}
                  </Button>
                </Nav.Link>
              </LinkContainer>
            </div>
          )}
        </Container>
      </Jumbotron>
    );
  }
}

export default ListeCategorie;
