//Importer image et thumbail
import Banquette1 from "./Banquette-Sprague-1.jpg";
import Banquette2 from "./Banquette-Sprague-2.jpg";
import Banquette3 from "./Banquette-Sprague-3.jpg";
import Banquette4 from "./Banquette-Sprague-4.jpg";
import Banquette5 from "./Banquette-Sprague-5.jpg";

//Construire la galerrie (check doc pour détail des clés)
const EGOUT_GALERIE = [
  {
    original: Banquette1,
    thumbnail: Banquette1,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
  {
    original: Banquette2,
    thumbnail: Banquette2,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
  {
    original: Banquette3,
    thumbnail: Banquette3,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
  {
    original: Banquette4,
    thumbnail: Banquette4,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
  {
    original: Banquette5,
    thumbnail: Banquette5,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
  {
    original: Banquette1,
    thumbnail: Banquette1,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
];
//Exporter la galerie
export default EGOUT_GALERIE;
