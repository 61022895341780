//Importer image et thumbail
import Louykopank1 from "./louykopank-XIV-1.jpg";
import Louykopank2 from "./louykopank-XIV-2.jpg";
import Louykopank3 from "./louykopank-XIV-3.jpg";

//Construire la galerrie (check doc pour détail des clés)
const LOUYPOKAMP_GALERIE = [
  {
    original: Louykopank1,
    thumbnail: Louykopank1,
    description: "Louykopank-XIV. Ykopank",
    originalTitle: "Louykopank-XIV. Ykopank",
    originalAlt: "Upcycling -Louykopank-XIV. Ykopank",
  },
  {
    original: Louykopank2,
    thumbnail: Louykopank2,
    description: "Louykopank-XIV. Ykopank",
    originalTitle: "Louykopank-XIV. Ykopank",
    originalAlt: "Upcycling -Louykopank-XIV. Ykopank",
  },
  {
    original: Louykopank3,
    thumbnail: Louykopank3,
    description: "Louykopank-XIV. Ykopank",
    originalTitle: "Louykopank-XIV. Ykopank",
    originalAlt: "Upcycling -Louykopank-XIV. Ykopank",
  },
];
//Exporter la galerie
export default LOUYPOKAMP_GALERIE;
