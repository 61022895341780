//Importer image et thumbail
import Table1 from "./table-scandinave-ykopank-1.jpg";
import Table2 from "./table-scandinave-ykopank-2.jpg";
import Table3 from "./table-scandinave-ykopank-3.jpg";
import Table4 from "./table-scandinave-ykopank-4.jpg";
import Table5 from "./table-scandinave-ykopank-5.jpg";

//Construire la galerrie (check doc pour détail des clés)
const TABLE_SCANDINAVE_GALERIE = [
  {
    original: Table1,
    thumbnail: Table1,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
  {
    original: Table2,
    thumbnail: Table2,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
  {
    original: Table3,
    thumbnail: Table3,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  }  ,
  {
    original: Table4,
    thumbnail: Table4,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  },
  {
    original: Table5,
    thumbnail: Table5,
    description: "Banquette Sprague. Ykopank",
    originalTitle: "Banquette Sprague. Ykopank",
    originalAlt: "Banquette Sprague. Ykopank",
  }
];
//Exporter la galerie
export default TABLE_SCANDINAVE_GALERIE;
