import React from "react";
import {Col,Container } from "react-bootstrap";
import Jumbotron from 'react-bootstrap/Jumbotron';
import atelierBG from "../Medias/atelier-upcycling.jpg" ;
import OtherProjectSuggest from "./OtherProjectSuggest";
import { Helmet } from "react-helmet";


const Legal = () => {
  return (
    
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Ykopank - upcycling - Legal</title>
                <meta name="description" content="Ykopank informations légales" />
            </Helmet>
      <Jumbotron style={{ 
      color:"#fff",
      fontFamily: "MONTSERRAT regular",        
      backgroundImage:`url(${atelierBG}), linear-gradient(45deg,#000 29.9%,#fff 70%)`, 
      filter: "grayscale(100%)",
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundBlendMode: "multiply",
      backgroundRepeat: 'no-repeat'}} fluid>
        
        <Container className="container-fluid" style={{backgroundBlendMode: "difference"}}>
          <Col sm={8}>
          <h1 style={{
        letterSpacing:"3px",
        color:"#fff",       
        fontWeight:"bolder",
        marginBottom:"30px",
        display:"flex",
        textTransform:"uppercase"
       }}>Mentions légales : ykopank-upcycling.com</h1>
      <br/>
      <h3 style={{
        letterSpacing:"3px",
        color:"#fff",
        fontWeight:"bolder",
        marginBottom:"30px",
        marginTop:"15px",
        textTransform:"uppercase",
        display:"flex",
        }}><span style={{borderBottom:"1px #fff solid"}}>Identification et objet du site</span></h3>
      <p>
        Le site Internet ykopank-upcycling.com à pour objet de présenter les oeuvres réalisées 
        par l'atelier de création et de surcyclage (<b>upcycling</b>) Ykopank. 
      <br /> <b>Ykopank upcycling</b> est édité par Arnaud Delattre pour la société Ykopank immatriculée 91916667800012.
      </p>
      <h3 style={{
        letterSpacing:"3px",
        color:"#fff",
        fontWeight:"bolder",
        marginBottom:"30px",
        marginTop:"15px",
        display:"flex",
        textTransform:"uppercase"
        }}><span style={{borderBottom:"1px #fff solid"}}>Hebergement </span></h3>
      <p>
      Le site Internet <b>ykopank-upcycling.com</b> est hébergé par : <br >
      </br>Netlify, dont le siège social est situé 2325 3rd Street, Suite 296, San Francisco, California 94107.
        <br />
        URL du site : www.netlify.com/
        <br />
        Contact : www.netlify.com/contact/
      </p>
      
      <h3 style={{
        letterSpacing:"3px",
        color:"#fff",        
        fontWeight:"bolder",
        marginBottom:"30px",
        marginTop:"15px",
        display:"flex",
        textTransform:"uppercase"
        }}><span style={{borderBottom:"1px #fff solid"}}>Contacter Ykopank upcycling</span></h3>
      <p>ykopank@gmail.com</p>       
              </Col>        
        
     
</Container>
</Jumbotron>
<Container>
  <h3 style={{paddingLeft:"10px",
        letterSpacing:"3px",
        color:"#555555",
        borderBottom:"1px #555555 solid",
        fontWeight:"bolder",
        borderLeft:"10px solid #555555"}}>Continuer la visite Ykopank Upcycling</h3>
 <OtherProjectSuggest />
 </Container></div>

  );
};
export default Legal;
