import React, { Component } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
 
} from "react-share";

/*import {
  FacebookShareCount,
  HatenaShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount,
} from "react-share";*/

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,

} from "react-share";


export default class ShareIt extends Component {
  hastag = (title)=>{
   // console.log(this.props)
    return ["Ykopank", "Upcycling", title];
  }
  render() {
     //console.log(this.props);
    return (
      <div>   

        
  <p style={{
        letterSpacing:"3px",
        textTransform:"uppercase", 
        color:"#888",  
        borderBottom:"1px #888 dotted"}}>Partagez {`${this.props.title}`} Ykopank</p>
        <EmailShareButton url={`${this.props.url}`} to="ykopank@gmail.com" subject={`Ykopank - Upcycling ${this.props.title}`} body={`Voici une oeuvre originale d'upcycling, je t'invite à la découvrir`}  >
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <FacebookShareButton url={`${this.props.url}`} hashtag={`#YokopankUpcycling${this.props.title}`} >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <LinkedinShareButton url={`${this.props.url}`} title={this.props.title} summary="" source="">
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton  url={`${this.props.url}`} via="Ykopank" title={this.props.title} hashtags={this.hastag(this.props.title)} caption="">
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton url={`${this.props.url}`} title={this.props.title}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton> 
        
        
      </div>
    );
  }
}
