import Odeleite1 from "./Ykopank-Odeleite-1.jpg";
import Odeleite11 from "./Ykopank-Odeleite-1_1.jpg";
import Odeleite2 from "./Ykopank-Odeleite-2.jpg";
import Odeleite21 from "./Ykopank-Odeleite-2_1.jpg";
import Odeleite3 from "./Ykopank-Odeleite-3.jpg";
import Odeleite31 from "./Ykopank-Odeleite-3_1.jpg";
import Odeleite4 from "./Ykopank-Odeleite-4.jpg";
import Odeleite41 from "./Ykopank-Odeleite-4_1.jpg";


const ODELEITE_GALERIE = [
  {
    original: Odeleite1,
    thumbnail: Odeleite1,
    description: "L'Odeleite. Ykopank",
    originalTitle: "L'Odeleite. Ykopank",
    originalAlt: "Upcycling -L'Odeleite. Ykopank",
  },  
  {
    original: Odeleite2,
    thumbnail: Odeleite2,
    description: "L'Odeleite. Ykopank",
    originalTitle: "L'Odeleite. Ykopank",
    originalAlt: "Upcycling -L'Odeleite. Ykopank",
  },
  
  {
    original: Odeleite3,
    thumbnail: Odeleite3,
    description: "L'Odeleite. Ykopank",
    originalTitle: "L'Odeleite. Ykopank",
    originalAlt: "Upcycling -L'Odeleite. Ykopank",
  },
  {
    original: Odeleite4,
    thumbnail: Odeleite4,
    description: "L'Odeleite. Ykopank",
    originalTitle: "L'Odeleite. Ykopank",
    originalAlt: "Upcycling -L'Odeleite. Ykopank",
  },
  {
    original: Odeleite11,
    thumbnail: Odeleite11,
    description: "L'Odeleite. Ykopank",
    originalTitle: "L'Odeleite. Ykopank",
    originalAlt: "Upcycling -L'Odeleite. Ykopank",
  },
  {
    original: Odeleite21,
    thumbnail: Odeleite21,
    description: "L'Odeleite. Ykopank",
    originalTitle: "L'Odeleite. Ykopank",
    originalAlt: "Upcycling -L'Odeleite. Ykopank",
  },
  {
    original: Odeleite31,
    thumbnail: Odeleite31,
    description: "L'Odeleite. Ykopank",
    originalTitle: "L'Odeleite. Ykopank",
    originalAlt: "Upcycling -L'Odeleite. Ykopank",
  },
  {
    original: Odeleite41,
    thumbnail: Odeleite41,
    description: "L'Odeleite. Ykopank",
    originalTitle: "L'Odeleite. Ykopank",
    originalAlt: "Upcycling -L'Odeleite. Ykopank",
  }
];

export default ODELEITE_GALERIE;